<template>
  <div class="card-body" >
    <p style="color:grey" v-if="withHint">
      {{ hint }}
    </p>
    <p class="card-title placeholder-glow">
      <span class="placeholder col-6"></span>
    </p>
    <p class="card-text placeholder-glow">
      <span class="placeholder col-7"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-4"></span>
      <span class="placeholder col-6"></span>
      <span class="placeholder col-8"></span>
    </p>
  </div>
</template>

<script>

import {defineComponent} from 'vue'

export default defineComponent({
  props: ['withHint','hint'],
});
</script>
